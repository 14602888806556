<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                    class="wizard wizard-2"
                    id="kt_wizard_v2"
                    data-wizard-state="step-first"
                    data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <div class="row">
                            <div class="col-6">
                                <h4>
                                    {{ inventoryAssignedListId ? 'Edit inventory assigned list ' : 'Add inventory assigned list' }}
                                </h4>
                                <router-link :to="{ name: 'dashboard' }">
                                Dashboard \
                                </router-link>
                                <router-link :to="{name: 'inventory-assigned-list'}">
                                Inventory assigned list
                                </router-link>

                            </div>
                        </div>

                        <div class="row">
                            <v-col cols="12" md="12" class="mt-3">
                                <v-autocomplete
                                    :items="inventoryItems"
                                    outlined
                                    dense
                                    :error="$v.inventoryAssignedList.inventory_item_id.$error"
                                    v-model="inventoryAssignedList.inventory_item_id"
                                    item-text="title"
                                    item-value="id"
                                    :disabled="inventoryAssignedListId"
                                    @change="handleInventoryItemChange"
                                >
                                    <template v-slot:label>
                                        Inventory item <span class="text-danger">*</span>
                                    </template>
                                </v-autocomplete>
                                <span class="text-danger" v-if="$v.inventoryAssignedList.inventory_item_id.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.inventory_item_id">{{ errors.inventory_item_id[0] }}</div>
                            </v-col>

                            <v-col cols="12" md="12" v-if="!inventoryAssignedListId">
                                <v-select
                                    outlined
                                    dense
                                    :error="$v.inventoryAssignedList.inventory_accession_id.$error"
                                    :loading="loadingAccession"
                                    :items="inventoryAccessions"
                                    item-text="bar_code"
                                    item-value="id"
                                    v-model="inventoryAssignedList.inventory_accession_id"
                                    :disabled="inventoryAccessions.length == 0"
                                >
                                    <template v-slot:label>
                                        Inventory accession
                                        <span class="text-danger"></span>
                                    </template>
                                </v-select>
                                <span class="text-danger" v-if="$v.inventoryAssignedList.inventory_accession_id.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.inventory_accession_id">{{ errors.inventory_accession_id[0] }}</div>
                            </v-col>

                            <!-- <v-col cols="12" md="12" class="mt-3">
                                <v-text-field
                                    outlined
                                    type="number"
                                    dense
                                    :disabled="!enabledAccession"
                                    :error="$v.inventoryAssignedList.qty.$error"
                                    v-model="inventoryAssignedList.qty"
                                >
                                    <template v-slot:label>Quantity <span class="text-danger">*</span></template>
                                </v-text-field>
                                <span class="text-danger" v-if="$v.inventoryAssignedList.qty.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.qty">{{ errors.qty[0] }}</div>
                                <div class="text-danger" v-if="errors.qty">{{ errors.qty[1] }}</div>
                            </v-col> -->

                            <v-col cols="12">
                                <v-autocomplete
                                    @change="selectUser"
                                    v-model="selectedUser"
                                    outlined
                                    dense
                                    :items="users"
                                    hide-selected
                                    item-text="display_text"
                                    item-value="id"
                                    :search-input.sync="userSearch"
                                    @input="inventoryAssignedList.user_id = $event !== null ? $event : ''"
                                    :loading="isUserLoading"
                                    clearable
                                    placeholder="Search by user name, email or CRN"
                                    :error="$v.inventoryAssignedList.user_id.$error"
                                >
                                <template v-slot:label>
                                    User <span class="text-danger">*</span>
                                </template>
                                <template v-slot:no-data>
                                    <div class="no-data-auto-complete text-center">No data available </div>
                                </template>
                                </v-autocomplete>
                                <span class="text-danger" v-if="$v.inventoryAssignedList.user_id.$error">This information is required</span>
                            </v-col>

                            <v-col cols="12" md="12">
                                <label>
                                    Assigned remarks <span class="text-danger">*</span>
                                </label>
                                <ckeditor
                                    v-model="inventoryAssignedList.assigned_remarks"
                                    :config="editorConfig"
                                ></ckeditor>
                                <span class="text-danger" v-if="$v.inventoryAssignedList.assigned_remarks.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.assigned_remarks">{{ errors.assigned_remarks[0] }}</div>

                            </v-col>

                            <v-col cols="12" md="6">
                                <v-autocomplete
                                    @change="selectAdminUser"
                                    v-model="selectedAdminUser"
                                    outlined
                                    dense
                                    :items="adminUsers"
                                    hide-selected
                                    item-text="display_text"
                                    item-value="id"
                                    :search-input.sync="adminUserSearch"
                                    @input="inventoryAssignedList.authorised_by = $event !== null ? $event : ''"
                                    :loading="isAdminUserLoading"
                                    clearable
                                    placeholder="Search by user name, email or CRN"
                                    :error="$v.inventoryAssignedList.authorised_by.$error"
                                >
                                <template v-slot:label>
                                    Authorised by<span class="text-danger">*</span>
                                </template>
                                <template v-slot:no-data>
                                    <div class="no-data-auto-complete text-center">No data available </div>
                                </template>
                                </v-autocomplete>
                                <span class="text-danger" v-if="$v.inventoryAssignedList.authorised_by.$error">This information is required</span>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="auto"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="menu"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        append-icon="mdi-calendar"
                                        clearable
                                        dense
                                        outlined
                                        readonly
                                        :error="$v.inventoryAssignedList.authorised_date.$error"
                                        v-bind="attrs"
                                        v-model="inventoryAssignedList.authorised_date"
                                        v-on="on"
                                    >
                                        <template v-slot:label>
                                            Authorised date
                                            <span class="text-danger">*</span>
                                        </template>
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    @input="menu = false"
                                    v-model="inventoryAssignedList.authorised_date"
                                ></v-date-picker>
                                </v-menu>
                                <span class="text-danger" v-if="$v.inventoryAssignedList.authorised_date.$error">This information is required</span>
                            </v-col>

                            <v-col cols="12" md="12">
                                <label>
                                    Authorised remarks <span class="text-danger">*</span>
                                </label>
                                <ckeditor
                                    v-model="inventoryAssignedList.authorised_remarks"
                                    :config="editorConfig"
                                ></ckeditor>
                                <span class="text-danger" v-if="$v.inventoryAssignedList.authorised_remarks.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.authorised_remarks">{{ errors.authorised_remarks[0] }}</div>

                            </v-col>
                        </div>


                            <div class="row">
                            <div class="col-12 text-right">
                                <v-btn
                                    class="text-gray btn btn-standard"
                                    depressed
                                    @click="cancel"
                                >Cancel
                                </v-btn>
                                <v-btn
                                    class="text-white ml-2 btn btn-primary"
                                    depressed
                                    @click="createOrUpdate"
                                    :loading="isBusy"
                                    v-if="checkIsAccessible('inventory-stock-register', 'create') || checkIsAccessible('inventory-stock-register', 'edit')"
                                >
                                  {{ inventoryAssignedListId ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>

</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import InventoryItemService from "@/core/services/inventory-item/InventoryItemService";
import InventoryAccessionService from "@/core/services/inventory-accession/InventoryAccessionService";
import InventoryAssignedListService from "@/core/services/inventory-assigned-list/InventoryAssignedListService";
import UserService from "@/core/services/user/UserService";

const inventoryItem = new InventoryItemService();
const inventoryAccession = new InventoryAccessionService();
const inventoryAssignedList = new InventoryAssignedListService();
const user = new UserService();

export default {
    validations: {
        inventoryAssignedList: {
            inventory_item_id: { required },
            user_id: { required },
            qty: {},
            assigned_remarks: {required},
            authorised_remarks: {required},
            authorised_by: {required},
            authorised_date: {required},
            inventory_accession_id:{
                required: requiredIf(function(nestedModel) {
                    return this.inventoryAccessionRequired;
                })
            }
        }
    },
    data() {
        return {
            dialog: false,
            isBusy: false,
            menu: false,
            editorConfig: {
              versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            inventoryAssignedList: {
                inventory_item_id: null,
                user_id: null,
                qty: 1,
                assigned_remarks: '',
                authorised_by: null,
                authorised_remarks: '',
                authorised_date: '',
                inventory_accession_id: null,
            },
            inventoryItems: [],
            errors: [],
            enabledAccession: false,
            userSearch: '',
            adminUserSearch: '',
            isUserLoading: false,
            isAdminUserLoading: false,
            adminUsers: [],
            users: [],
            inventoryAccessions: [],
            loadingAccession: false,
            inventoryAccessionRequired: false,
        };
    },
    mounted(){
        this.getInventoryItems();
        if(this.inventoryAssignedListId){
            this.getInventoryAssignmentList();
        }
    },
    computed:{
        inventoryAssignedListId(){
            return this.$route.params.inventoryAssignedListId;
        }
    },
    methods: {
        cancel(){
            this.$tabs.close();
        },
        getInventoryItems() {
            inventoryItem
            .all()
            .then(response => {
                this.inventoryItems = response.data.inventoryItems;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        createOrUpdate() {
            this.$v.inventoryAssignedList.$touch();
            if (this.$v.inventoryAssignedList.$error) {
                setTimeout(() => {
                    this.$v.inventoryAssignedList.$reset();
                }, 3000);
            } else {
                if (this.inventoryAssignedListId) {
                    this.updateInventoryAssignmentList();
                } else {
                    this.createInventoryAssignmentList();
                }
            }
        },
        updateInventoryAssignmentList() {
            this.isBusy = true;
            inventoryAssignedList
            .update(this.inventoryAssignedList.id, this.inventoryAssignedList)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.$tabs.close();
                this.resetForm();
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createInventoryAssignmentList() {
            this.isBusy = true;
            inventoryAssignedList
            .store(this.inventoryAssignedList)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$snotify.success("Information added");
                this.$tabs.close();
            })
            .catch(error => {
                this.isBusy = false;
                this.errors = error.errors;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.inventoryAssignedList.$reset();
            this.inventoryAssignedList = {
                inventory_item_id: null,
                qty: 1,
                assigned_remarks: '',
                authorised_remarks: '',
                inventory_accession_id: null
            };
        },
        getInventoryAssignmentList(){
            inventoryAssignedList
            .show(this.inventoryAssignedListId)
            .then((res) => {
                this.inventoryAssignedList = res.data.inventoryAssignedList;
                if(this.inventoryAssignedList.user_id){
                    this.searchUser(this.inventoryAssignedList.user_email)
                }
                if(this.inventoryAssignedList.authorised_by){
                    this.searchAdminUser(this.inventoryAssignedList.authorised_by_email)
                }
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        },
        getInventoryAccessionStatus(){
            inventoryItem
            .getAccessionStatus(this.inventoryAssignedList.inventory_item_id)
            .then((res) => {
                this.enabledAccession = res.data.accessionStatus;
            })
            .catch(err => {

            })
        },
        handleInventoryItemChange(){
            if(this.inventoryAssignedList.inventory_item_id){
                this.getInventoryItemAccessions()
            }
        },
        getInventoryItemAccessions(){
            this.loadingAccession = true;
            inventoryAccession
            .getByInventoryItem(this.inventoryAssignedList.inventory_item_id)
            .then((res) => {
                this.inventoryAccessions = res.data.inventoryAccessions;
                if(res.data.inventoryAccessions.length == 0){
                    this.inventoryAccessionRequired = false;
                    this.inventoryAssignedList.inventory_accession_id = null;
                }else{
                    this.inventoryAccessionRequired = true;
                }
            })
            .catch((err) => {

            })
            .finally(() => {
                this.loadingAccession = false;
            });
        },
        selectUser()
        {
            if(this.selectedUser && this.selectedUser != null){
                this.inventoryAssignedList.user_id = this.selectedUser;
            }else{
                this.inventoryAssignedList.user_id = "";
            }
        },
        selectedUser(val)
        {
            if(!val){
                this.inventoryAssignedList.user_id ='';
            }
        },
        selectAdminUser()
        {
            if(this.selectedAdminUser && this.selectedAdminUser != null){
                this.inventoryAssignedList.authorised_by = this.selectedAdminUser;
            }else{
                this.inventoryAssignedList.authorised_by = "";
            }
        },
        selectedAdminUser(val)
        {
            if(!val){
                this.inventoryAssignedList.authorised_by ='';
            }
        },
        searchUser(val){
            this.isUserLoading = true;

            let data = {
                val: val,
                type: 'not_student'
            };

            user
            .filter(data)
            .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.full_name +
                        " | " +
                        user.personal_id +
                        " | " +
                        user.email
                    this.users.push(data);
                });
                this.selectedUser = response.data.users[0];
            })
            .catch((err) => {

            })
            .finally(() => (this.isUserLoading = false));
        },

        searchAdminUser(val){
            this.isAdminUserLoading = true;

            let data = {
                val: val,
                type: 'admin'
            };

            user
            .filter(data)
            .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.full_name +
                        " | " +
                        user.personal_id +
                        " | " +
                        user.email
                    this.adminUsers.push(data);
                });
                this.selectedAdminUser = response.data.users[0];
            })
            .catch((err) => {

            })
            .finally(() => (this.isAdminUserLoading = false));
        },
    },
    watch:{
        userSearch(val) {
            this.isUserLoading = true;

            let data = {
                val: val,
                type: 'not_student'
            };

            user
            .filter(data)
            .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.full_name +
                        " | " +
                        user.personal_id +
                        " | " +
                        user.email
                    this.users.push(data);
                });
            })
            .catch((err) => {
            })
            .finally(() => (this.isUserLoading = false));
        },
        adminUserSearch(val) {
            this.isAdminUserLoading = true;

            let data = {
                val: val,
                type: 'admin'
            };

            user
            .filter(data)
            .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.full_name +
                        " | " +
                        user.personal_id +
                        " | " +
                        user.email
                    this.adminUsers.push(data);
                });
            })
            .catch((err) => {
            })
            .finally(() => (this.isAdminUserLoading = false));
        },
    }
}
</script>
